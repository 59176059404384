<template>
  <div>
    <div class="ctl-table-container">
      <pil-catalog-filters
        @refresh-filters="refreshItemsList"
        @refresh-sort-params="refreshItemsListWithSort"
        :catalogTab="tab"
      />
      <div class="ctl-titles">
        <label class="checkbox-container">
          <input
            type="checkbox"
            hidden
            ref="selectAll"
            @input="updateCheckedItemsList"
          />
          <div class="checkbox-item"></div>
        </label>
        <div class="grid">
          <span
            class="ctl-titles-item"
            v-for="(t, idx) of titlesList"
            :key="idx"
            >{{ t.name }}</span
          >
        </div>
      </div>
      <div class="ctl-items-list">
        <draggable class="steps-items" v-model="items">
          <div
            class="ctl-item"
            :class="{ reversed: idx % 2 }"
            v-for="(t, idx) of items"
            :key="idx"
          >
            <label class="checkbox-container">
              <input
                type="checkbox"
                hidden
                :value="t"
                v-model="checkedItemsMod"
              />
              <div class="checkbox-item" :class="{ reversed: idx % 2 }"></div>
            </label>
            <div class="ctl-item-container grid" @click="showEditPopup(t)">
              <!-- position -->
              <div class="ctl-item-param">
                {{ t.position }}
              </div>
              <!-- name -->
              <div class="ctl-item-param">
                {{ t.name }}
              </div>
              <!-- pages -->
              <div class="ctl-item-param pages">
                <div
                  v-for="(s, idx) in t.pages"
                  :key="idx"
                  class="step-item steps-container item drag-item"
                >
                  <img alt="img" v-if="s && s.img" :src="s.img" />
                </div>
              </div>
              <!-- created at -->
              <div class="ctl-item-param">
                {{ getCorrectDate(t.created_at).slice(0, 19) }}
              </div>
              <!-- content manager -->
              <div class="ctl-item-param">
                {{ t.content_manager && t.content_manager.email }}
              </div>
              <!-- rating
            <div class="ctl-item-param">
              <div class="smallbold center">
                {{ t.rating || 0 }}
              </div>
            </div> -->
            </div>
          </div>
          <div class="ctl-loader">
            <div
              v-infinity-scroll="{
                cb: fetchPathItemsList,
                tab
              }"
              id="scroll-trigger"
            ></div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import PilCatalogFilters from "@/components/catalog/Filters";
import draggable from "vuedraggable";
import catalogMixin from "@/mixins/catalogMixin";
import "@/assets/css/catalog.css";

export default {
  name: "InteractionsCatalog",
  props: ["tab"],
  mixins: [catalogMixin],
  components: {
    PilCatalogFilters,
    draggable
  },
  data() {
    return {
      // ? filters
      filters: {},
      sortParams: { position: "asc" },
      // ? titles
      titlesList: [
        { name: "position" },
        { name: "name" },
        { name: "description" },
        { name: "pages" },
        { name: "created at" },
        { name: "content manager" }
      ]
    };
  },
  computed: {
    items: {
      get() {
        return this.$store.getters["catalog/interactions"];
      },
      set(value) {
        this.$store.dispatch("catalog/updateInteractionsOrder", value);
      }
    }
  }
};
</script>

<style scoped>
.grid {
  display: grid;
  white-space: nowrap;
  grid-template-columns:
    minmax(70px, 70fr)
    minmax(150px, 150fr)
    minmax(500px, 500fr)
    minmax(150px, 150fr)
    minmax(150px, 150fr)
    minmax(200px, 200px);
  grid-gap: 1vw;
  align-items: center;
  justify-items: center;
  min-width: fit-content;
  width: 100%;
}
.ctl-item-param.pages {
  display: flex;
  overflow: auto;
}
.steps-container {
  margin: 10px 7px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  min-width: 60px;
  height: 108px;
  border: none;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  display: flex;
  overflow: hidden;
}
.step-item img {
  max-height: 100%;
}
</style>
